'use strict';

angular.module('webPartnerPortalApp')
  .controller('LoginCtrl', ['$scope','$http', '$location','EventDispatchingSvc','loader', function ($scope, $http, $location, EventDispatchingSvc, loader) {
    var LoginCtrlVM = this;
    LoginCtrlVM.errorMsg = '';
    angular.extend(LoginCtrlVM, new EventDispatchingSvc($scope));
    LoginCtrlVM.login = function(){
      loader.show();
      $http({
      method: 'POST',
      url: '/api/login',
      data : LoginCtrlVM.credentials
    }).then(function successCallback(response) {
        var headerObject = {
             first_name : response.data.first_name,
             last_name : response.data.last_name,
             role : response.data.portal_user_role_id
        };
           LoginCtrlVM.dispatch('access:login', headerObject);
           $location.url('/partners');
        }, function errorCallback(response) {
          console.log(response);
          if(response.status === 403){
            LoginCtrlVM.errorMsg = response.data.message;
          }else{
            if(response.data == 'session expired'){
              LoginCtrlVM.login();
            }
            LoginCtrlVM.invalidPassword = true;
          }
        loader.close();
      });
    };
    LoginCtrlVM.init = function(){};

    LoginCtrlVM.init();

  }]);
